module directives {
    export module tariff {
        interface ICommodityCodeScope extends ng.IScope {
            showTarifDetail();
            sectionNumber: number;
            nationalMeasureCode: string;
            dutyTypeCode: string;
            dutyTypeId: number;
            countryId: number;
            wcoEnabled:boolean;
            simulationDate: moment.Moment;
            exclusions: boolean;
            tariffChapters: Array<interfaces.tariffbook.ITarrifBookChapter>;
            showTariffBookNotes(dutyTypeId: number, countryId: number, simulationDate: moment.Moment, chapterNumber: string)
        }

        export class commodityCodeDirective implements ng.IDirective {
            templateUrl = 'templates/modules/applicationmain/tariff/commodityCodeView.html';
            scope = {
                sectionNumber: "=",
                nationalMeasureCode: "=",
                dutyTypeCode: "=",
                dutyTypeId: "=",
                countryId: "=", 
                simulationDate: "=",
                exclusions: "=",
                wcoEnabled: "="
            }

            messages: interfaces.applicationcore.IMessage[];
            isLoading: boolean = false;

            tariffChapters: Array<interfaces.tariffbook.ITarrifBookChapter>; 

            constructor(
                private generalService: interfaces.applicationcore.IGeneralService,
                public $uibModal: ng.ui.bootstrap.IModalService,
                private hsCodeService: interfaces.tariff.IHsCodeService,
                private $state: ng.ui.IStateService,
                private bsLoadingOverlayService,
                private menuService: interfaces.applicationcore.IMenuService,
                private tariffBookService: interfaces.tariffbook.ITariffBookService
            ) {

            }

            link = ($scope: ICommodityCodeScope, $element: ng.IAugmentedJQuery, $state) => {
                $scope.showTariffBookNotes = (dutyTypeId: number, countryId: number, simulationDate: moment.Moment, chapterNumber: string) => {
                    this.showTariffBookNotes(dutyTypeId, countryId, simulationDate, chapterNumber)
                }

                this.bsLoadingOverlayService.wrap({
                    referenceId: 'tariff.commodityCode'
                }, () => {
                    return this.loadChapters($scope.sectionNumber,
                        $scope.dutyTypeId,
                        $scope.countryId,
                        $scope.simulationDate,
                        $scope.exclusions,$scope);
                });
            }

            loadChapters(sectionNumber: number, dutyTypeId: number, countryId: number, simulationDate: moment.Moment, exclusions: boolean, $scope: ICommodityCodeScope) {

                return this.tariffBookService.getChaptersBySection().query({
                    sectionNumber: sectionNumber,
                    type: dutyTypeId,
                    countryId: countryId,
                    simulationDate: simulationDate.format("YYYYMMDDTHH:mm"),
                    exclusions:  exclusions
                }, (resultList) => {

                    this.tariffChapters = resultList;
                    this.isLoading = false;
                    
                    $scope.tariffChapters = this.tariffChapters;

                }, (errorResponse) => {
                    this.isLoading = false;
                    if (errorResponse.data.Messages) {
                        this.messages = [];
                    }

                    angular.forEach(errorResponse.data.Messages, (o) => {
                        angular.forEach(o.MessageItems, (n) => {
                            this.messages.push(n);
                        });
                    });
                }).$promise;
            }

            showTariffBookNotes(dutyTypeId: number, countryId: number, simulationDate: moment.Moment, chapterNumber: string) {

                return this.tariffBookService.getTariffBookNotes().query({
                    type: dutyTypeId,
                    countryId: countryId,
                    simulationDate: simulationDate.format("YYYYMMDDTHH:mm"),
                    code: chapterNumber,
                    isSection: false
                }, (data) => {

                    return this.displayNotes(data.Notes);

                }, (errorResponse) => {
                    this.isLoading = false;
                    if (errorResponse.data.Messages) {
                        this.messages = [];
                    }

                    angular.forEach(errorResponse.data.Messages, (o) => {
                        angular.forEach(o.MessageItems, (n) => {
                            this.messages.push(n);
                        });
                    });
                }).$promise;
            }


            displayNotes(notes: string) {
                this.$uibModal.open({
                    animation: true,
                    template: `
                        <div class="modal-content">
                            <div class="modal-header">
                                <h3 class="modal-title" id="modal-title">Notes</h3>
                            </div>
                            <div class="modal-body" id="modal-body">
                                <p ng-bind-html="Notes"></p>
                            </div>
                            <div class="modal-footer">
                                <button class="btn btn-primary" type="button" ng-click="ok()">OK</button>
                            </div>
                        </div>
                    `,
                    controller: ($scope, $uibModalInstance: ng.ui.bootstrap.IModalInstanceService) => {
                        $scope.Notes = notes;

                        $scope.ok = () => {
                            $uibModalInstance.dismiss();
                        }
                    },
                    size: "lg",
                    resolve: {
                    }
                });
            }

            static factory(): ng.IDirectiveFactory {
                const directive = (generalService, $uibModal, hsCodeService, $state,
                    bsLoadingOverlayService, menuService, tariffBookService) => new commodityCodeDirective(generalService, $uibModal, hsCodeService, $state, bsLoadingOverlayService, menuService, tariffBookService);
                directive.$inject = ["generalService", "$uibModal", "hsCodeService", "$state", "bsLoadingOverlayService", "menuService","tariffBookService"];

                return directive;
            }
        }

        angular.module("app").directive("gtsCommodityCode", commodityCodeDirective.factory());
    }
}